<template>
  <Component :is="icons[name]"></Component>
</template>
<script setup lang="ts">
import IPzoAccessibility from "~icons/pzo/accessibility";
import IPzoAccountBalance from "~icons/pzo/account-balance";
import IPzoAccountCircle from "~icons/pzo/account-circle";
import IPzoAddLocationAlt from "~icons/pzo/add-location-alt";
import IPzoAddNotes from "~icons/pzo/add-notes";
import IPzoAdd from "~icons/pzo/add";
import IPzoAssignmentAdd from "~icons/pzo/assignment-add";
import IPzoAssignmentTurnedIn from "~icons/pzo/assignment-turned-in";
import IPzoAssistantOnHub from "~icons/pzo/assistant-on-hub";
import IPzoAttachFileAdd from "~icons/pzo/attach-file-add";
import IPzoAttachFile from "~icons/pzo/attach-file";
import IPzoAttachment from "~icons/pzo/attachment";
import IPzoCalendarMonth from "~icons/pzo/calendar-month";
import IPzoCall from "~icons/pzo/call";
import IPzoCampaign from "~icons/pzo/campaign";
import IPzoChatInfo from "~icons/pzo/chat-info";
import IPzoChat from "~icons/pzo/chat";
import IPzoChevronLeft from "~icons/pzo/chevron-left";
import IPzoChevronRight from "~icons/pzo/chevron-right";
import IPzoClinicalNotes from "~icons/pzo/clinical-notes";
import IPzoCloudDownload from "~icons/pzo/cloud-download";
import IPzoContactPhone from "~icons/pzo/contact-phone";
import IPzoContactSupport from "~icons/pzo/contact-support";
import IPzoContrast from "~icons/pzo/contrast";
import IPzoDataAlert from "~icons/pzo/data-alert";
import IPzoDescription from "~icons/pzo/description";
import IPzoDownload from "~icons/pzo/download";
import IPzoEditDocument from "~icons/pzo/edit-document";
import IPzoEdit from "~icons/pzo/edit";
import IPzoError from "~icons/pzo/error";
import IPzoExpandLess from "~icons/pzo/expand-less";
import IPzoExpandMore from "~icons/pzo/expand-more";
import IPzoFileSave from "~icons/pzo/file-save";
import IPzoFolder from "~icons/pzo/folder";
import IPzoFormatListBulleted from "~icons/pzo/format-list-bulleted";
import IPzoForwardToInbox from "~icons/pzo/forward-to-inbox";
import IPzoGavel from "~icons/pzo/gavel";
import IPzoGlobeUk from "~icons/pzo/globe-uk";
import IPzoHistoryEdu from "~icons/pzo/history-edu";
import IPzoHomePin from "~icons/pzo/home-pin";
import IPzoHomeWork from "~icons/pzo/home-work";
import IPzoHome from "~icons/pzo/home";
import IPzoHotelClass from "~icons/pzo/hotel-class";
import IPzoInfo from "~icons/pzo/info";
import IPzoLeaderboard from "~icons/pzo/leaderboard";
import IPzoLibraryBooks from "~icons/pzo/library-books";
import IPzoLink from "~icons/pzo/link";
import IPzoListAlt from "~icons/pzo/list-alt";
import IPzoLocalLibrary from "~icons/pzo/local-library";
import IPzoLocationHome from "~icons/pzo/location-home";
import IPzoLocationOn from "~icons/pzo/location-on";
import IPzoMail from "~icons/pzo/mail";
import IPzoMap from "~icons/pzo/map";
import IPzoMarkUnreadChatAlt from "~icons/pzo/mark-unread-chat-alt";
import IPzoMenu from "~icons/pzo/menu";
import IPzoMic from "~icons/pzo/mic";
import IPzoModeOfTravel from "~icons/pzo/mode-of-travel";
import IPzoMusicNote from "~icons/pzo/music-note";
import IPzoNews from "~icons/pzo/news";
import IPzoNotListedLocation from "~icons/pzo/not-listed-location";
import IPzoNotes from "~icons/pzo/notes";
import IPzoNotifications from "~icons/pzo/notifications";
import IPzoPayments from "~icons/pzo/payments";
import IPzoPersonAdd from "~icons/pzo/person-add";
import IPzoPerson from "~icons/pzo/person";
import IPzoPhoneInTalk from "~icons/pzo/phone-in-talk";
import IPzoPowerSettingsNew from "~icons/pzo/power-settings-new";
import IPzoPrivacyTip from "~icons/pzo/privacy-tip";
import IPzoQueryStats from "~icons/pzo/query-stats";
import IPzoQuickReference from "~icons/pzo/quick-reference";
import IPzoReorder from "~icons/pzo/reorder";
import IPzoRewardedAds from "~icons/pzo/rewarded-ads";
import IPzoSchool from "~icons/pzo/school";
import IPzoSettings from "~icons/pzo/settings";
import IPzoSportsSoccer from "~icons/pzo/sports-soccer";
import IPzoSportsVolleyball from "~icons/pzo/sports-volleyball";
import IPzoTableView from "~icons/pzo/table-view";
import IPzoTextFields from "~icons/pzo/text-fields";
import IPzoTextSelectMoveDown from "~icons/pzo/text-select-move-down";
import IPzoTrophy from "~icons/pzo/trophy";
import IPzoVideocam from "~icons/pzo/videocam";
import IPzoVolumeUp from "~icons/pzo/volume-up";
import IPzoWarning from "~icons/pzo/warning";
import IPzoSearch from "~icons/pzo/search";
import IPzoQuickReferenceAll from "~icons/pzo/quick-reference-all";
import IPzoDataLossPrevention from "~icons/pzo/data-loss-prevention";
import IPzoRefresh from "~icons/pzo/refresh";
import IPzoStar from "~icons/pzo/star";
import IPzoFavorite from "~icons/pzo/favorite";
import IPzoFullscreen from "~icons/pzo/fullscreen";
import IPzoManageSearch from "~icons/pzo/manage-search";
import IPzoSync from "~icons/pzo/sync";
import IPzoBlock from "~icons/pzo/block";
import IPzoSort from "~icons/pzo/sort";
import IPzoMenuOpen from "~icons/pzo/menu-open";
import IPzoFilterList from "~icons/pzo/filter-list";
import IPzoArrowForward from "~icons/pzo/arrow-forward";
import IPzoArrowBack from "~icons/pzo/arrow-back";
import IPzoApps from "~icons/pzo/apps";
import IPzoMoreHoriz from "~icons/pzo/more-horiz";
import IPzoMoreVert from "~icons/pzo/more-vert";
import IPzoDelete from "~icons/pzo/delete";
import IPzoLogout from "~icons/pzo/logout";
import IPzoDone from "~icons/pzo/done";
import IPzoClose from "~icons/pzo/close";
import IPzoHistory from "~icons/pzo/history";
import IPzoNestClockFarsightAnalog from "~icons/pzo/nest-clock-farsight-analog";
import IPzoSchedule from "~icons/pzo/schedule";
import IPzoCheckCircle from "~icons/pzo/check-circle";
import IPzoOverview from "~icons/pzo/overview";
import IPzoCalculate from "~icons/pzo/calculate";
import IPzoAddPhotoAlternate from "~icons/pzo/add-photo-alternate";
import IPzoFormatAlignCenter from "~icons/pzo/format-align-center";
import IPzoFormatAlignJustify from "~icons/pzo/format-align-justify";
import IPzoFormatAlignLeft from "~icons/pzo/format-align-left";
import IPzoFormatAlignRight from "~icons/pzo/format-align-right";
import IPzoFormatBold from "~icons/pzo/format-bold";
import IPzoFormatColorFill from "~icons/pzo/format-color-fill";
import IPzoFormatColorText from "~icons/pzo/format-color-text";
import IPzoFormatIndentDecrease from "~icons/pzo/format-indent-decrease";
import IPzoFormatIndentIncrease from "~icons/pzo/format-indent-increase";
import IPzoFormatItalic from "~icons/pzo/format-italic";
import IPzoFormatListNumbered from "~icons/pzo/format-list-numbered";
import IPzoFormatUnderlined from "~icons/pzo/format-underlined";
import IPzoHorizontalSplit from "~icons/pzo/horizontal-split";
import IPzoImage from "~icons/pzo/image";
import IPzoList from "~icons/pzo/list";
import IPzoMovie from "~icons/pzo/movie";
import IPzoRedo from "~icons/pzo/redo";
import IPzoStrikethroughS from "~icons/pzo/strikethrough-s";
import IPzoSubscript from "~icons/pzo/subscript";
import IPzoSuperscript from "~icons/pzo/superscript";
import IPzoTable from "~icons/pzo/table";
import IPzoUndo from "~icons/pzo/undo";
import IPzoVerticalSplit from "~icons/pzo/vertical-split";
import IPzoRemove from "~icons/pzo/remove";
import IPzoCellMerge from "~icons/pzo/cell-merge";
import IPzoArrowSplit from "~icons/pzo/arrow-split";
import IPzoViewSidebar from "~icons/pzo/view-sidebar";
import IPzoHelp from "~icons/pzo/help";
import IPzoCode from "~icons/pzo/code";
import IPzoSignature from "~icons/pzo/signature";
import IPzoExclamation from "~icons/pzo/exclamation";
import IPzoInfoNoCircle from "~icons/pzo/info-no-circle";
import IPzoLanguage from "~icons/pzo/language";
import IPzoMyLocation from "~icons/pzo/my-location";
import IPzoFax from "~icons/pzo/fax";
import IPzoLike from "~icons/pzo/like";
import IPzoDislike from "~icons/pzo/dislike";
import IPzoLikeFull from "~icons/pzo/like-full";
import IPzoDislikeFull from "~icons/pzo/dislike-full";

import type { PzoIcon } from "../types/icons";

interface Props {
  name: PzoIcon;
}
defineProps<Props>();

const icons = {
  accessibility: IPzoAccessibility,
  "account-balance": IPzoAccountBalance,
  "account-circle": IPzoAccountCircle,
  "add-location-alt": IPzoAddLocationAlt,
  "add-notes": IPzoAddNotes,
  add: IPzoAdd,
  "assignment-add": IPzoAssignmentAdd,
  "assignment-turned-in": IPzoAssignmentTurnedIn,
  "assistant-on-hub": IPzoAssistantOnHub,
  "attach-file-add": IPzoAttachFileAdd,
  "attach-file": IPzoAttachFile,
  attachment: IPzoAttachment,
  "calendar-month": IPzoCalendarMonth,
  call: IPzoCall,
  campaign: IPzoCampaign,
  "chat-info": IPzoChatInfo,
  chat: IPzoChat,
  "chevron-left": IPzoChevronLeft,
  "chevron-right": IPzoChevronRight,
  "clinical-notes": IPzoClinicalNotes,
  "cloud-download": IPzoCloudDownload,
  "contact-phone": IPzoContactPhone,
  "contact-support": IPzoContactSupport,
  contrast: IPzoContrast,
  "data-alert": IPzoDataAlert,
  description: IPzoDescription,
  download: IPzoDownload,
  "edit-document": IPzoEditDocument,
  edit: IPzoEdit,
  error: IPzoError,
  "expand-less": IPzoExpandLess,
  "expand-more": IPzoExpandMore,
  "file-save": IPzoFileSave,
  folder: IPzoFolder,
  "format-list-bulleted": IPzoFormatListBulleted,
  "forward-to-inbox": IPzoForwardToInbox,
  gavel: IPzoGavel,
  "globe-uk": IPzoGlobeUk,
  "history-edu": IPzoHistoryEdu,
  "home-pin": IPzoHomePin,
  "home-work": IPzoHomeWork,
  home: IPzoHome,
  "hotel-class": IPzoHotelClass,
  info: IPzoInfo,
  leaderboard: IPzoLeaderboard,
  "library-books": IPzoLibraryBooks,
  link: IPzoLink,
  "list-alt": IPzoListAlt,
  "local-library": IPzoLocalLibrary,
  "location-home": IPzoLocationHome,
  "location-on": IPzoLocationOn,
  mail: IPzoMail,
  map: IPzoMap,
  "mark-unread-chat-alt": IPzoMarkUnreadChatAlt,
  menu: IPzoMenu,
  mic: IPzoMic,
  "mode-of-travel": IPzoModeOfTravel,
  "music-note": IPzoMusicNote,
  news: IPzoNews,
  "not-listed-location": IPzoNotListedLocation,
  notes: IPzoNotes,
  notifications: IPzoNotifications,
  payments: IPzoPayments,
  "person-add": IPzoPersonAdd,
  person: IPzoPerson,
  "phone-in-talk": IPzoPhoneInTalk,
  "power-settings-new": IPzoPowerSettingsNew,
  "privacy-tip": IPzoPrivacyTip,
  "query-stats": IPzoQueryStats,
  "quick-reference": IPzoQuickReference,
  reorder: IPzoReorder,
  "rewarded-ads": IPzoRewardedAds,
  school: IPzoSchool,
  settings: IPzoSettings,
  "sports-soccer": IPzoSportsSoccer,
  "sports-volleyball": IPzoSportsVolleyball,
  "table-view": IPzoTableView,
  "text-fields": IPzoTextFields,
  "text-select-move-down": IPzoTextSelectMoveDown,
  trophy: IPzoTrophy,
  videocam: IPzoVideocam,
  "volume-up": IPzoVolumeUp,
  warning: IPzoWarning,
  search: IPzoSearch,
  "quick-reference-all": IPzoQuickReferenceAll,
  "data-loss-prevention": IPzoDataLossPrevention,
  refresh: IPzoRefresh,
  star: IPzoStar,
  favorite: IPzoFavorite,
  fullscreen: IPzoFullscreen,
  "manage-search": IPzoManageSearch,
  sync: IPzoSync,
  block: IPzoBlock,
  sort: IPzoSort,
  "menu-open": IPzoMenuOpen,
  "filter-list": IPzoFilterList,
  "arrow-forward": IPzoArrowForward,
  "arrow-back": IPzoArrowBack,
  apps: IPzoApps,
  "more-horiz": IPzoMoreHoriz,
  "more-vert": IPzoMoreVert,
  delete: IPzoDelete,
  logout: IPzoLogout,
  done: IPzoDone,
  close: IPzoClose,
  history: IPzoHistory,
  "nest-clock-farsight-analog": IPzoNestClockFarsightAnalog,
  schedule: IPzoSchedule,
  "check-circle": IPzoCheckCircle,
  overview: IPzoOverview,
  calculate: IPzoCalculate,
  "add-photo-alternate": IPzoAddPhotoAlternate,
  "format-align-center": IPzoFormatAlignCenter,
  "format-align-justify": IPzoFormatAlignJustify,
  "format-align-left": IPzoFormatAlignLeft,
  "format-align-right": IPzoFormatAlignRight,
  "format-bold": IPzoFormatBold,
  "format-color-fill": IPzoFormatColorFill,
  "format-color-text": IPzoFormatColorText,
  "format-indent-decrease": IPzoFormatIndentDecrease,
  "format-indent-increase": IPzoFormatIndentIncrease,
  "format-italic": IPzoFormatItalic,
  "format-list-numbered": IPzoFormatListNumbered,
  "format-underlined": IPzoFormatUnderlined,
  "horizontal-split": IPzoHorizontalSplit,
  image: IPzoImage,
  list: IPzoList,
  movie: IPzoMovie,
  redo: IPzoRedo,
  "strikethrough-s": IPzoStrikethroughS,
  subscript: IPzoSubscript,
  superscript: IPzoSuperscript,
  table: IPzoTable,
  undo: IPzoUndo,
  "vertical-split": IPzoVerticalSplit,
  remove: IPzoRemove,
  "cell-merge": IPzoCellMerge,
  "arrow-split": IPzoArrowSplit,
  "view-sidebar": IPzoViewSidebar,
  help: IPzoHelp,
  code: IPzoCode,
  signature: IPzoSignature,
  exclamation: IPzoExclamation,
  "info-no-circle": IPzoInfoNoCircle,
  language: IPzoLanguage,
  "my-location": IPzoMyLocation,
  fax: IPzoFax,
  like: IPzoLike,
  dislike: IPzoDislike,
  "like-full": IPzoLikeFull,
  "dislike-full": IPzoDislikeFull,
};
</script>
